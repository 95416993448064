<template>
  <div class="contract">
    <canvas id="contract-canvas" @click="$imagePreview([contractImg])"></canvas>
    <!--    <img class="img" src="@/assets/images/contract.jpg" alt="" @click="$imagePreview([require('@/assets/images/contract.jpg')])">-->
    <!--    <img class="signature" :src="signature1" alt="">-->
    <!--    <img class="signature" :src="signature2" alt="">-->
    <div class="btns">
      <my-button class="btn" @click="signature(1)">受让人签字</my-button>
      <my-button class="btn" @click="signature(2)">权益转让人签字</my-button>
    </div>
    <my-button class="btn confirm" @click="confirm" :disabled="!signature1||!signature2">确认</my-button>
  </div>
</template>

<script>
import request from "@/utils/http";

export default {
  name: "contract",
  data() {
    return {
      canvas: null,
      ctx: null,
      contractImg: '',
      signature1: '',
      signature2: '',
      policyNumber: '0123456789',
      model: 'iPhone 13',
      damageTime: ['2022', '01', '13'],
      transferor: '程建',
      assignee: '李双江',
      signatureTime: ['2022', '01', '13'],
      assigneePhone: '18888888888'
    }
  },
  mounted() {
    this.policyNumber = this.$route.query.policyNumber
    this.model = this.$route.query.model
    this.transferor = this.$route.query.transferor
    this.assignee = this.$route.query.assignee
    this.assigneePhone = this.$route.query.assigneePhone
    this.damageTime = this.formatDate(this.$route.query.damageTime)
    this.signatureTime = this.formatDate(new Date())

    this.canvas = document.getElementById('contract-canvas');
    this.ctx = this.canvas.getContext('2d')
    this.signature1 = sessionStorage.getItem('signature1') || ''
    this.signature2 = sessionStorage.getItem('signature2') || ''

    this.draw()
  },
  methods: {
    formatDate(dateStr) {
      if (!dateStr) return ''
      let date = new Date(dateStr)
      return [
        date.getFullYear() + '',
        date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1) + '',
        date.getDate() < 10 ? '0' + date.getDate() : date.getDate() + '',
      ]
    },
    signature(type) {
      this.$router.push({
        name: 'signature',
        query: {type}
      })
    },
    draw() {
      let screenWidth = window.innerWidth
      let contractImg = new Image()
      contractImg.src = require('@/assets/images/contract.jpg')
      contractImg.onload = () => {
        let width = contractImg.width
        let height = contractImg.height
        this.canvas.style.width = screenWidth + 'px'
        this.canvas.style.height = screenWidth * contractImg.height / contractImg.width + 'px'
        this.canvas.setAttribute('width', width + '')
        this.canvas.setAttribute('height', height + '')

        this.ctx.drawImage(contractImg, 0, 0, width, height)

        this.policyNumber && this.drawText(this.policyNumber, 665, 390)
        this.model && this.drawText(this.model, 220, 428)
        this.damageTime[0] && this.drawText(this.damageTime[0], 530, 428)
        this.damageTime[1] && this.drawText(this.damageTime[1], 640, 428)
        this.damageTime[2] && this.drawText(this.damageTime[2], 715, 428)
        this.transferor && this.drawText(this.transferor, 250, 463)
        this.assignee && this.drawText(this.assignee, 620, 463)
        this.assigneePhone && this.drawText(this.assigneePhone, 408, 656)
        this.signatureTime[0] && this.drawText(this.signatureTime[0], 290, 692)
        this.signatureTime[0] && this.drawText(this.signatureTime[0], 830, 692)
        this.signatureTime[1] && this.drawText(this.signatureTime[1], 372, 692)
        this.signatureTime[1] && this.drawText(this.signatureTime[1], 919, 692)
        this.signatureTime[2] && this.drawText(this.signatureTime[2], 420, 692)
        this.signatureTime[2] && this.drawText(this.signatureTime[2], 965, 692)

        if (this.signature1) {
          this.ctx.save()
          let signature1 = new Image();
          signature1.src = this.signature1
          signature1.onload = () => {
            this.ctx.translate(360, 630)
            this.ctx.rotate(-Math.PI / 2);
            this.ctx.drawImage(signature1, 0, 0, 100 * signature1.width / signature1.height, 100)
            this.ctx.restore()

            this.contractImg = this.canvas.toDataURL('image/jpg')
          }
          // this.uploadImg(this.signature1,+new Date()+'.png').then(res=>{
          //   this.signature1=res&&res.data&&res.data.url||''
          //   sessionStorage.setItem('signature1Url',this.signature1)
          // })
        }

        if (this.signature2) {
          this.ctx.save()
          let signature2 = new Image();
          signature2.src = this.signature2
          signature2.onload = () => {
            this.ctx.translate(955, 630)
            this.ctx.rotate(-Math.PI / 2);
            this.ctx.drawImage(signature2, 0, 0, 100 * signature2.width / signature2.height, 100)
            this.ctx.restore()
            this.contractImg = this.canvas.toDataURL('image/jpg')
          }

          // this.uploadImg(this.signature2,+new Date()+'.png').then(res=>{
          //   this.signature2=res&&res.data&&res.data.url||''
          //   sessionStorage.setItem('signature2Url',this.signature2)
          // })
        }
        this.contractImg = this.canvas.toDataURL('image/jpg')

      }
    },
    drawText(text, x, y, font = '20px Microsoft YaHei') {
      this.ctx.font = font
      this.ctx.fillText(text, x, y)
    },
    uploadImg(dataurl, filename) {
      if(!dataurl)return
      let arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1]
      let bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      let file= new File([u8arr], filename, {type: mime});

      let data = new FormData();
      data.append('file', file)
      return request({
        url: '/Uploadify/imageUp',
        method: 'post',
        data
      })
    },
    confirm() {
      this.$router.go(-1)
    }
  },
}
</script>

<style scoped>
.img {
  width: 100%;
}

.btns {
  position: fixed;
  bottom: 170px;
  left: 0;
  display: flex;
  justify-content: space-between;
  padding: 0 30px;
  width: 100vw;
}

.btns .btn {
  width: 320px;
}

.signature {
  width: 100px;
  transform-origin: 0 0;
  transform: rotate(-90deg);
}

.confirm {
  width: calc(100vw - 60px);
  margin-left: 30px;
  position: fixed;
  bottom: 40px;
}
</style>
